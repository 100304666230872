import 'aframe'
import SpeechRecognition from 'react-speech-recognition'

import {
  ActivityMode,
  DEFAULT_TIMEOUT,
  getMaxScoreAnswerByLevenshtein,
  registerCompleteBtn,
  registerSkipBtn,
  registerStartBtn,
  SPEECH_LANGUAGES,
  VrComponentOptions,
  VrMode
} from '../../../../shared'

export function registerBasePanel(params) {
  const {
    questions,
    resetTranscript,
    setAnswersResults,
    browserSupportsSpeechRecognition,
    language,
    mode,
    guidedAnalyze,
    freeSpeechAnalyze,
    transcribe,
    finishTracking,
    saveQuestionResult,
    completeCallback,
    startCallback,
    activityMode
  } = params

  delete AFRAME.components['info-panel']
  let mediaRecorder = null
  let resultId = null
  let correctAnswersCount = 0
  let timer = null
  let mainStream = null
  let isRecording = false

  registerSkipBtn()
  if (mode === VrMode.interactive) {
    registerCompleteBtn(async () => {
      const now = new Date().toISOString()
      await finishTracking({ id: resultId, finishedAt: now }).then(() => {
        completeCallback()
      })
    })
    registerStartBtn(async () => {
      await startCallback().then((res) => {
        resultId = res.id
      })
    })
  } else {
    registerCompleteBtn(completeCallback)
    registerStartBtn()
  }

  AFRAME.registerComponent('info-panel', {
    ...VrComponentOptions,
    dependencies: ['skip-button', 'complete-button', 'start-button'],
    init: function () {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          mainStream = stream
          this.isEnabledMicro = true
        })
        .catch(() => {
          // eslint-disable-next-line
          console.error('Microphone access denied')
          alert('Microphone access denied')
          this.isEnabledMicro = false
        })

      this.ui = this.getElementById('ui')
      this.videoEl = this.getElementById('video')

      questions.forEach((question, index) => {
        this.videoEl = this.getElementById('video')
        this.videoEl.pause()
        this[`isAnsweredQuestion_${question.id}`] = false
        const isHelpExist = this.getElementById(`help_${question.id}`)

        this.videoEl.addEventListener('timeupdate', () => {
          if (
            this.videoEl.currentTime >= question.breakpoint &&
            !this[`isAnsweredQuestion_${question.id}`]
          ) {
            this.videoEl.pause()
            this.toggleElementVisibility(`questionWrapper_${question.id}`, true)
            this[`isAnsweredQuestion_${question.id}`] = true

            this.actionsVisibilityToggle(question.id, true)

            if (isHelpExist) {
              this.helpOpenBtnStateToggle(question.id, true)
            }
          }
        })

        this.getElementById(`recordButton_${question.id}`).addEventListener(
          'click',
          () => {
            this.recordBtnClick(question.id, question.answers)
          }
        )

        if (this.getElementById(`repeatButton_${question.id}`)) {
          this.getElementById(`repeatButton_${question.id}`).addEventListener(
            'click',
            () => {
              this[`isAnsweredQuestion_${question.id}`] = false
              this.repeatBtnClick(question.id, questions, index)
            }
          )
        }

        if (isHelpExist) {
          this.getElementById(`helpOpenButton_${question.id}`).addEventListener(
            'click',
            () => this.toggleHelp(question.id, false)
          )
          this.getElementById(
            `helpCloseButton_${question.id}`
          ).addEventListener('click', () => this.toggleHelp(question.id, true))
        }
      })

      this.recordAnswer = this.recordAnswer.bind(this)
      this.recordBtnClick = this.recordBtnClick.bind(this)
      this.clearTimeoutCb = this.clearTimeoutCb.bind(this)
      this.guidedCalculateResult = this.guidedCalculateResult.bind(this)
      this.freeSpeechCalculateResult = this.freeSpeechCalculateResult.bind(this)
      this.hideElements = this.hideElements.bind(this)
      this.stopRecording = this.stopRecording.bind(this)

      this.videoEl.addEventListener('ended', () => {
        this.completeBtnStateToggle(true)
        if (mode !== VrMode.preview) {
          this.toggleElementVisibility('resultsWrapper', true)
        }
      })
      this.ui.object3D.position.x = 0
      this.ui.object3D.position.y = 1.6
      this.ui.object3D.position.z = -2.5
      if (AFRAME.utils.device.isMobileVR()) {
        this.ui.object3D.position.z = -3.5
        this.ui.object3D.position.y = 1.25
      }
    },

    toggleRecordBtnIsRecording: function (questionId, isRecording) {
      const btn = this.getElementById(`recordButton_${questionId}`)
      if (isRecording) {
        btn.object3D.position.x = 0
        btn.setAttribute('material', 'color', '#ff0000')
      } else {
        if (activityMode === ActivityMode.free_speech) {
          btn.object3D.position.x = 0
        } else {
          btn.object3D.position.x = 0.17
        }
        btn.setAttribute('material', 'color', '#ffffff')
      }
    },

    guidedCalculateResult: async function (answers, formData) {
      const maxScoreAnswer = getMaxScoreAnswerByLevenshtein(
        window.transcription,
        answers
      )

      if (maxScoreAnswer.score >= 70) {
        const result = {
          text: maxScoreAnswer.text,
          id: maxScoreAnswer.id
        }

        if (maxScoreAnswer.isCorrect) {
          this.toggleElementVisibility(`correct_${maxScoreAnswer.id}`, true)
          setAnswersResults((prev) => [...prev, { ...result, isCorrect: true }])

          if (formData) {
            correctAnswersCount += 1
            const score = Math.round(
              (correctAnswersCount / questions.length) * 100
            )
            await saveQuestionResult({
              id: resultId,
              score
            })
            await guidedAnalyze({
              id: maxScoreAnswer.id,
              formData
            })
          }
        } else {
          this.toggleElementVisibility(`incorrect_${maxScoreAnswer.id}`, true)
          setAnswersResults((prev) => [
            ...prev,
            { ...result, isCorrect: false }
          ])
        }

        return true
      }
      return false
    },

    freeSpeechCalculateResult: async function (questionId, formData) {
      const isExistAnswer = window.transcription.length > 0
      if (isExistAnswer) {
        setAnswersResults((prev) => [
          ...prev,
          { id: questionId, text: window.transcription, isCorrect: true }
        ])

        if (formData) {
          correctAnswersCount += 1
          const score = Math.round(
            (correctAnswersCount / questions.length) * 100
          )
          await saveQuestionResult({
            id: resultId,
            score
          })
          formData.append('answerText', window.transcription)
          await freeSpeechAnalyze({
            questionId,
            formData
          })
        }
        return isExistAnswer
      }

      return isExistAnswer
    },

    stopRecording: function (questionId, answers) {
      mediaRecorder.stop()

      mediaRecorder.ondataavailable = async (e) => {
        this.processingMessageVisibilityToggle(questionId, true)

        const fileName = `recorded_audio_${Date.now()}.wav`
        const audioBlob = new Blob([e.data], { type: 'audio/wav' })

        if (browserSupportsSpeechRecognition) {
          await SpeechRecognition.stopListening()
        } else {
          const formData = new FormData()
          formData.append('file', audioBlob, fileName)
          formData.append('language', language)
          await transcribe(formData)
        }

        this.processingMessageVisibilityToggle(questionId, false)

        let isSuccess
        if (mode === VrMode.interactive) {
          const formDataToAnalyze = new FormData()
          formDataToAnalyze.append('file', audioBlob, fileName)
          formDataToAnalyze.append('resultId', resultId)

          if (activityMode === ActivityMode.free_speech) {
            isSuccess = await this.freeSpeechCalculateResult(
              questionId,
              formDataToAnalyze
            )
          } else {
            isSuccess = await this.guidedCalculateResult(
              answers,
              formDataToAnalyze
            )
          }
        } else {
          if (activityMode === ActivityMode.free_speech) {
            isSuccess = await this.freeSpeechCalculateResult(questionId)
          } else {
            isSuccess = await this.guidedCalculateResult(answers)
          }
        }

        if (isSuccess) {
          setTimeout(() => {
            this.videoEl.play()
            this.hideElements(questionId)
          }, 1250)
        } else {
          this.helpOpenBtnStateToggle(questionId, true)
          this.retryMessageVisibilityToggle(questionId, true)
          this.repeatBtnStateToggle(questionId, true)
          this.recordBtnStateToggle(questionId, true)
          this.skipBtnStateToggle(questionId, true)
        }
      }
    },

    clearTimeoutCb: function (questionId, answers) {
      clearTimeout(timer)
      timer = null
      this.stopRecording(questionId, answers)

      this.recordBtnStateToggle(questionId, false)
      this.toggleRecordBtnIsRecording(questionId, false)
      isRecording = false
    },

    recordBtnClick: async function (questionId, answers) {
      if (!this.isEnabledMicro) {
        // eslint-disable-next-line no-console
        console.error('Microphone access denied')
        alert('Microphone access denied')
        return
      }

      if (isRecording) {
        this.clearTimeoutCb(questionId, answers)
      } else {
        timer = setTimeout(
          () => this.clearTimeoutCb(questionId, answers),
          DEFAULT_TIMEOUT
        )

        this.recordAnswer()

        this.helpOpenBtnStateToggle(questionId, false)
        this.helpCloseBtnStateToggle(questionId, false)
        this.retryMessageVisibilityToggle(questionId, false)
        this.repeatBtnStateToggle(questionId, false)
        this.skipBtnStateToggle(questionId, false)
        this.toggleRecordBtnIsRecording(questionId, true)
        isRecording = true
      }
    },

    recordAnswer: async function () {
      // Not all browsers support Web Speech API
      if (browserSupportsSpeechRecognition) {
        resetTranscript()
        await SpeechRecognition.startListening({
          language: SPEECH_LANGUAGES[language] ?? 'en-US',
          continuous: true
        })
      }

      mediaRecorder = new MediaRecorder(mainStream)
      mediaRecorder.start()
    }
  })
}
