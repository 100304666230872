import { Entity } from 'aframe'
import axios from 'axios'

import {
  ACCESS_DEMO_TOKEN_NAME,
  ACCESS_TOKEN_NAME,
  API_ROUTES,
  REFRESH_TOKEN_NAME,
  SPEECH_LANGUAGES,
  hintWrapCount,
  oneRowHeight,
  questionTitleLineHeight,
  questionTitleMarginTop,
  questionTitleWrapCount,
  recordActionsHeight
} from '../constants'
import {
  ActivityMode,
  IAnswer,
  IQuestion,
  TokenResponse,
  VrMode
} from '../types'

export * from './aframe-components'

export const getAverageScore = (answersResults: IAnswer[]) => {
  let rightAnswersCount = 0
  answersResults.forEach((ans: any) => {
    if (ans.isCorrect) rightAnswersCount += 1
  })
  return Math.round((rightAnswersCount / answersResults.length) * 100) || 0
}

export const getFreeSpeechAverageScore = (
  answeredQuestionsCount: number,
  allQquestions: number
) => {
  return Math.round((answeredQuestionsCount / allQquestions) * 100) || 0
}

export const calculateQuestionTitleMarginTop = (numberOfLines: number) => {
  return questionTitleMarginTop + numberOfLines * questionTitleLineHeight
}

export const getFirstAnswerPaddingTop = (questionLength: number) => {
  const linesCount = Math.floor(questionLength / questionTitleWrapCount) || 1
  const questionTitleTopPosition = calculateQuestionTitleMarginTop(linesCount)

  return questionTitleTopPosition + questionTitleLineHeight * linesCount + 0.1
}
export const getTitlePosition = (
  rowNumber: number,
  modalHeight: number,
  questionLength: number
) => {
  const paddingTop = getFirstAnswerPaddingTop(questionLength)
  const positionX = -0.6
  let positionY = modalHeight / 2 - paddingTop
  const positionZ = 0

  if (rowNumber > 0) {
    positionY = positionY - 0.2 * rowNumber
  }

  return `${positionX} ${positionY} ${positionZ}`
}

export const getCheckIconPosition = (
  rowNumber: number,
  modalHeight: number,
  questionLength: number
) => {
  const paddingTop = getFirstAnswerPaddingTop(questionLength)
  const positionX = 0.62
  let positionY = modalHeight / 2 - paddingTop
  const positionZ = 0.01

  if (rowNumber > 0) {
    positionY = positionY - 0.2 * rowNumber
  }

  return `${positionX} ${positionY} ${positionZ}`
}

export const getTextWrapCount = (text: string) => {
  return text.split('\n').length - 1
}

export const getModalTitlePosition = (
  modalHeight: number,
  questionLength: number
) => {
  const linesCount = Math.floor(questionLength / 30) || 1
  return `-0.68 ${
    modalHeight / 2 - calculateQuestionTitleMarginTop(linesCount)
  } 0`
}

export const getQuestionTitleHeight = (linesCount: number) => {
  return linesCount * questionTitleLineHeight
}

export const getQuestionModalHeight = (
  answersCount: number,
  questionTitleLength: number
) => {
  const linesCount =
    Math.floor(questionTitleLength / questionTitleWrapCount) || 1
  const titleHeight = getQuestionTitleHeight(linesCount)
  const titleMarginTop = calculateQuestionTitleMarginTop(linesCount)

  const answersHeight = answersCount * oneRowHeight

  return titleHeight + titleMarginTop + answersHeight + recordActionsHeight
}

export const getActionsPosition = (modalHeight: number) => {
  const bottomPadding = 0.25
  return `0 -${modalHeight / 2 - bottomPadding} 0.1`
}

export const getActionsXPositions = (countBtns: number): string[] => {
  if (countBtns === 1) return ['0']

  const btnWidth = 0.17
  const totalWidth = countBtns * btnWidth
  const step = totalWidth / (countBtns - 1)
  const positions = []

  for (let i = 0; i < countBtns; i++) {
    const position = i * step - totalWidth / 2
    positions.push(position.toString())
  }

  return positions
}

export const getHelpPointButtonPosition = (modalHeight: number) => {
  return `0.6 ${modalHeight / 2 - calculateQuestionTitleMarginTop(1)} 0.01`
}

export const getHelpWrapperHeight = (
  hint: string,
  modalHeight: number,
  language: string
) => {
  const wrapCount =
    hintWrapCount[SPEECH_LANGUAGES[language]] || hintWrapCount.default
  const hintLinesCount = Math.floor(hint.length / wrapCount) || 1
  const hintHeight = getQuestionTitleHeight(
    hintLinesCount + getTextWrapCount(hint)
  )

  const topPadding = getFirstAnswerPaddingTop(1)
  const minHeight = modalHeight - topPadding

  return hintHeight < minHeight ? minHeight : hintHeight
}

export const getNotificationIconPosition = (modalHeight: number) => {
  const bottomPadding = 0.45
  const x = modalHeight / 2 - bottomPadding
  return `-0.55 -${x < 0 ? 0 : x} 0.1`
}

export const getNotificationTextPosition = (modalHeight: number) => {
  const bottomPadding = 0.46
  const x = modalHeight / 2 - bottomPadding
  return `-0.45 -${x < 0 ? 0 : x} 0.1`
}

export const VrComponentOptions = {
  removeClickEventsClasses: function (element: Entity<HTMLElement>) {
    element.classList.remove('raycastable', 'menu-button')
  },
  addClickEventsClasses: function (element: Entity<HTMLElement>) {
    element.classList.add('raycastable', 'menu-button')
  },
  getElementById: function <T = Entity<HTMLElement>>(
    id: string
  ): T | null | undefined {
    return document.getElementById(id) as T | null | undefined
  },
  toggleElementClickEvents: function (id: string, isEnabled: boolean) {
    const element = this.getElementById(id)
    if (!element) return
    if (isEnabled) {
      this.addClickEventsClasses(element)
    } else {
      this.removeClickEventsClasses(element)
    }
  },
  toggleElementVisibility: function (id: string, isVisible: boolean) {
    const element = this.getElementById(id)
    if (!element) return
    element.object3D.visible = isVisible
  },
  actionsVisibilityToggle: function (questionId: string, visible: boolean) {
    this.toggleElementVisibility(`actions_${questionId}`, visible)

    this.repeatBtnStateToggle(questionId, visible)
    this.recordBtnStateToggle(questionId, visible)
    this.skipBtnStateToggle(questionId, visible)
  },

  recordBtnStateToggle: function (questionId: string, visible: boolean) {
    this.toggleElementClickEvents(`recordButton_${questionId}`, visible)
    this.toggleElementVisibility(`recordButton_${questionId}`, visible)
  },

  skipBtnStateToggle: function (questionId: string, visible: boolean) {
    this.toggleElementClickEvents(`skipButton_${questionId}`, visible)
    this.toggleElementVisibility(`skipButton_${questionId}`, visible)
  },

  completeBtnStateToggle: function (visible: boolean) {
    this.toggleElementClickEvents('completeButton', visible)
    this.toggleElementVisibility('completeButton', visible)
  },

  repeatBtnStateToggle: function (questionId: string, visible: boolean) {
    this.toggleElementClickEvents(`repeatButton_${questionId}`, visible)
    this.toggleElementVisibility(`repeatButton_${questionId}`, visible)
  },

  retryMessageVisibilityToggle: function (
    questionId: string,
    visible: boolean
  ) {
    this.toggleElementVisibility(`retryIcon_${questionId}`, visible)
    this.toggleElementVisibility(`retryText_${questionId}`, visible)
  },

  processingMessageVisibilityToggle: function (
    questionId: string,
    visible: boolean
  ) {
    this.toggleElementVisibility(`processingIcon_${questionId}`, visible)
    this.toggleElementVisibility(`processingText_${questionId}`, visible)
  },

  helpOpenBtnStateToggle: function (questionId: string, visible: boolean) {
    this.toggleElementClickEvents(`helpOpenButton_${questionId}`, visible)
    this.toggleElementVisibility(`helpOpenButton_${questionId}`, visible)
  },

  helpCloseBtnStateToggle: function (questionId: string, visible: boolean) {
    this.toggleElementVisibility(`helpCloseButton_${questionId}`, visible)
    this.toggleElementClickEvents(`helpCloseButton_${questionId}`, visible)
  },

  hideElements: function (questionId: string) {
    this.helpCloseBtnStateToggle(questionId, false)
    this.helpOpenBtnStateToggle(questionId, false)

    this.toggleElementVisibility(`questionWrapper_${questionId}`, false)

    this.toggleElementVisibility(`help_${questionId}`, false)

    this.actionsVisibilityToggle(questionId, false)

    this.retryMessageVisibilityToggle(questionId, false)
    this.processingMessageVisibilityToggle(questionId, false)
  },
  toggleHelp: function (questionId: string, visible: boolean) {
    this.toggleElementVisibility(`questionWrapper_${questionId}`, visible)
    this.actionsVisibilityToggle(questionId, visible)

    this.helpCloseBtnStateToggle(questionId, !visible)
    this.helpOpenBtnStateToggle(questionId, visible)
    this.toggleElementVisibility(`help_${questionId}`, !visible)
  },
  repeatBtnClick: function (
    questionId: string,
    questions: IQuestion[],
    currentIndex: number
  ) {
    const videoEl = this.getElementById<HTMLVideoElement>(
      'video'
    ) as HTMLVideoElement
    if (!videoEl) return
    const prevBreakpoint =
      currentIndex === 0 ? 0 : questions[currentIndex - 1].breakpoint
    videoEl.currentTime = prevBreakpoint + 0.1

    this.hideElements(questionId)
    videoEl.play()
  }
}

export const logVrInfo = (
  mode: VrMode,
  activityMode: ActivityMode,
  browserSupportsSpeechRecognition: boolean,
  recognitionIsEnabled: boolean,
  useAiModel: boolean
) => {
  // eslint-disable-next-line
  console.group('VrInfo')
  // eslint-disable-next-line
  console.log('mode:', mode)
  // eslint-disable-next-line
  console.log('activityMode:', activityMode)
  // eslint-disable-next-line
  console.log(
    'browser support speech recognition:',
    browserSupportsSpeechRecognition
  )
  // eslint-disable-next-line
  console.log('recognition is enabled:', recognitionIsEnabled)
  // eslint-disable-next-line
  console.log('use ai model:', !useAiModel)
  // eslint-disable-next-line
  console.groupEnd()
}

export const removeTokens = () => {
  sessionStorage.removeItem(ACCESS_TOKEN_NAME)
  sessionStorage.removeItem(REFRESH_TOKEN_NAME)
}

export const setTokens = (accessToken: string, refreshToken: string) => {
  sessionStorage.setItem(ACCESS_TOKEN_NAME, accessToken)
  sessionStorage.setItem(REFRESH_TOKEN_NAME, refreshToken)
}

export const refreshDemoToken = async () => {
  const { data } = await axios.post<TokenResponse>(
    `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/${API_ROUTES.LOGIN_DEMO_USER}`
  )
  sessionStorage.setItem(ACCESS_DEMO_TOKEN_NAME, data.accessToken)
  return data.accessToken
}

export const refreshAuthToken = async (refreshToken: string) => {
  try {
    const { data } = await axios.post<TokenResponse>(
      `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/${API_ROUTES.REFRESH}`,
      {
        refreshToken
      }
    )
    setTokens(data.accessToken, data.refreshToken)
    return data.accessToken
  } catch (error) {
    removeTokens()
  }
}

export const getCurrentToken = async () => {
  const accessToken = sessionStorage.getItem(ACCESS_TOKEN_NAME)
  if (accessToken) return accessToken

  const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_NAME)
  if (refreshToken) {
    const result = await refreshAuthToken(refreshToken)
    if (result) {
      return result
    }
  }

  const accessDemoToken = sessionStorage.getItem(ACCESS_DEMO_TOKEN_NAME)
  if (accessDemoToken) return accessDemoToken

  return await refreshDemoToken()
}

export const refreshCurrentToken = async () => {
  const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_NAME)
  if (refreshToken) {
    const result = await refreshAuthToken(refreshToken)
    if (result) {
      return result
    }
  }
  return await refreshDemoToken()
}

export const levenshteinDistance = (a: string, b: string) => {
  const matrix = []
  for (let i = 0; i <= a.length; i++) {
    matrix[i] = [i]
  }
  for (let j = 0; j <= b.length; j++) {
    matrix[0][j] = j
  }
  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      if (a.charAt(i - 1) === b.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1]
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
        )
      }
    }
  }
  return matrix[a.length][b.length]
}

export const similarityScore = (original: string, input: string) => {
  const formattedOriginal = original.toLowerCase()
  const formattedInput = input.toLowerCase()
  const distance = levenshteinDistance(formattedOriginal, formattedInput)
  const maxLength = Math.max(original.length, input.length)
  const score = (maxLength - distance) / maxLength
  return Math.round(score * 100)
}

export const getMaxScoreAnswerByLevenshtein = (
  transcription: string,
  answers: IAnswer[]
): {
  id: string
  text: string
  score: number
  isCorrect: boolean
} => {
  const ansScore = answers.map((answer) => {
    const score = similarityScore(answer.text, transcription)
    return {
      score,
      id: answer.id,
      isCorrect: answer.isCorrect,
      text: answer.text
    }
  })

  const maxScoreAnswer = ansScore.reduce((maxScoreAnswer, currentAnswer) => {
    return currentAnswer.score > maxScoreAnswer.score
      ? currentAnswer
      : maxScoreAnswer
  }, ansScore[0])

  return maxScoreAnswer
}
