import 'aframe'
import 'aframe-troika-text'
import React, { FC, Fragment } from 'react'

import {
  Theme,
  necessaryElementsHeight,
  oneRowHeight,
  resultTitleMarginTop
} from '../../constants'
import {
  getAverageScore,
  getFreeSpeechAverageScore,
  getTitlePosition
} from '../../helpers'
import { useCustomFont } from '../../hooks'
import { ActivityMode, IAnswer } from '../../types'
import AnswerTitle from '../answer-title'
import CompleteButton from '../complete-button'

interface Props {
  answersResults: IAnswer[]
  allQuestionsCount: number
  activityMode: ActivityMode
}

const ResultsWrapper: FC<Props> = ({
  answersResults,
  activityMode,
  allQuestionsCount
}) => {
  const fontProps = useCustomFont()

  const bottomButtonMargin = 0.2
  const bottomScoreMargin = 0.4
  let correctAnswersCount = 0
  let incorrectAnswersCount = 0

  answersResults.forEach((res) => {
    if (res.isCorrect) {
      correctAnswersCount += 1
    } else {
      incorrectAnswersCount += 1
    }
  })

  const resultsModalHeight = 2 * oneRowHeight + necessaryElementsHeight
  const modalTitlePosition = `-0.67 ${
    resultsModalHeight / 2 - resultTitleMarginTop
  } 0.01`
  const completeButtonPosition = `0 -${
    resultsModalHeight / 2 - bottomButtonMargin
  } 0.1`

  const averageScorePosition = `-0.67 -${
    resultsModalHeight / 2 - bottomScoreMargin
  } 0.01`

  const currentAverageScore =
    activityMode === ActivityMode.free_speech
      ? getFreeSpeechAverageScore(answersResults.length, allQuestionsCount)
      : getAverageScore(answersResults)

  return (
    <a-entity
      id={`resultsWrapper`}
      position="0 -1.5 0.5"
      geometry={`primitive: plane; width: 1.5; height: ${resultsModalHeight}`}
      material={`src: url(/assets/modal-wrapper.svg); transparent: true;`}
      visible="false"
    >
      <a-entity
        geometry={`primitive: plane; width: 1.4; height: ${
          resultsModalHeight - 0.1
        };`}
        material={`color: #ffffff`}
        visible="true"
      >
        <a-entity position={modalTitlePosition}>
          <a-troika-text
            anchor="left"
            align="left"
            value="Your results:"
            position="0 0 0"
            color={Theme.colors.black}
            font-size="0.1"
            {...fontProps}
          />
        </a-entity>
        {activityMode === ActivityMode.free_speech ? (
          <AnswerTitle
            id="free_speech_answers_count"
            position={getTitlePosition(0, resultsModalHeight, 1)}
            value={`Answered questions: ${answersResults.length}`}
            negate={false}
          />
        ) : (
          <Fragment>
            <AnswerTitle
              id="correct_answers_count"
              position={getTitlePosition(0, resultsModalHeight, 1)}
              value={`Correct answers count: ${correctAnswersCount}`}
              negate={false}
            />
            <AnswerTitle
              id="incorrect_answers_count"
              position={getTitlePosition(1, resultsModalHeight, 1)}
              value={`Incorrect answers count: ${incorrectAnswersCount}`}
              negate={false}
            />
          </Fragment>
        )}

        {activityMode !== ActivityMode.free_speech && (
          <a-entity position={averageScorePosition}>
            <a-troika-text
              anchor="left"
              align="left"
              value={`Average score: ${currentAverageScore}%`}
              position="0 0 0"
              color={Theme.colors.black}
              font-size="0.05"
              {...fontProps}
            />
          </a-entity>
        )}

        <CompleteButton position={completeButtonPosition} />
      </a-entity>
    </a-entity>
  )
}

export default ResultsWrapper
