import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useLazyGetActivityQuery } from '../../app/api/activityApi'
import { VrMode, setTokens } from '../../shared'
import Loader from '../../shared/ui/loader'
import VrSceneWidget from '../../widgets/vr-scene-widget'
import { Root } from './styles'

const PreviewPage: FC = () => {
  const params = useParams<{
    activityId: string
  }>()
  const mode = VrMode.preview

  const [getActivity, { isLoading, data }] = useLazyGetActivityQuery()

  const completeCallback = useCallback(() => {
    location.reload()
  }, [])

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      if (event.data.key == 'tokens') {
        const { accessToken, refreshToken } = event.data.data
        setTokens(accessToken, refreshToken)

        getActivity({ isDemo: false, activityId: params.activityId as string })
        window.removeEventListener('message', handleMessage)
      }
    },
    [getActivity, params.activityId]
  )

  if (!data) {
    window.addEventListener('message', handleMessage)
  }

  if (isLoading || !data) return <Loader />

  return (
    <Root>
      <VrSceneWidget
        mode={mode}
        activityMode={data.mode}
        questions={data.questions}
        sceneUrl={data.sceneUrl}
        language={data.unit.course.language || 'en'}
        completeCallback={completeCallback}
      />
    </Root>
  )
}

export default PreviewPage
