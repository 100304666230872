import 'aframe'
import React, { FC } from 'react'

const Actions: FC<{
  id: string
  position: string
  children: React.ReactNode
  visible?: boolean
}> = (props) => {
  const { id, position, children, visible } = props
  return (
    <a-entity id={`actions_${id}`} position={position} visible={!!visible}>
      {children}
    </a-entity>
  )
}

export default Actions
