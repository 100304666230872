import 'aframe'
import React, { FC } from 'react'

const SkipButton: FC<{
  id: string
  position?: string
}> = (props) => {
  return (
    <a-entity
      skip-button={`questionId: ${props.id}`}
      id={`skipButton_${props.id}`}
      geometry="primitive: plane;width: 0.2; height: 0.2;"
      material="src: url(/assets/skip.png); transparent: true;"
      animation__scale="property: scale; to: 1.1 1.1 1.1; dur: 200; startEvents: mouseenter"
      animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
      position={props.position || '0 0 0'}
    />
  )
}

export default SkipButton
