import { FC, Fragment, memo, useCallback, useMemo } from 'react'

import {
  IQuestion,
  ActivityMode,
  getQuestionModalHeight,
  getActionsXPositions,
  getActionsPosition,
  getModalTitlePosition,
  getHelpPointButtonPosition,
  getHelpWrapperHeight,
  getTitlePosition,
  getCheckIconPosition
} from '../../../../shared'
import Actions from '../../../../shared/ui/actions'
import AnswerItem from '../../../../shared/ui/answer-item'
import CompleteButton from '../../../../shared/ui/complete-button'
import HelpPointButton from '../../../../shared/ui/help-point-button'
import HelpWrapper from '../../../../shared/ui/help-wrapper'
import QuestionTitle from '../../../../shared/ui/question-title'
import QuestionWrapper from '../../../../shared/ui/question-wrapper'
import RepeatButton from '../../../../shared/ui/repeat-button'
import SkipButton from '../../../../shared/ui/skip-button'

interface Props {
  questions: IQuestion[]
  activityMode: ActivityMode
  language: string
}

const Preview: FC<Props> = ({ questions, activityMode, language }) => {
  const [firstX, secondX] = useMemo(() => getActionsXPositions(2), [])

  const getComponents = useCallback(() => {
    return (
      <Fragment>
        {questions.map((question) => {
          const answersCount =
            activityMode !== ActivityMode.free_speech
              ? question.answers.length
              : 1
          const modalHeight = getQuestionModalHeight(
            answersCount,
            question.text.length
          )
          const actionsPosition = getActionsPosition(modalHeight)

          return (
            <Fragment key={question.id}>
              <QuestionWrapper
                id={`questionWrapper_${question.id}`}
                height={modalHeight}
              >
                <QuestionTitle
                  id={`question_${question.id}`}
                  value={question.text}
                  position={getModalTitlePosition(
                    modalHeight,
                    question.text.length
                  )}
                />
                {activityMode !== ActivityMode.free_speech &&
                  question.answers.map((answer, key) => {
                    return (
                      <AnswerItem
                        key={`key_${answer.id}`}
                        answerId={answer.id}
                        isCorrect={answer.isCorrect}
                        titlePosition={getTitlePosition(
                          key,
                          modalHeight,
                          question.text.length
                        )}
                        value={answer.text}
                        checkIconPosition={getCheckIconPosition(
                          key,
                          modalHeight,
                          question.text.length
                        )}
                      />
                    )
                  })}

                <Actions id={question.id} position={actionsPosition}>
                  <RepeatButton id={question.id} position={`${firstX} 0 0`} />
                  <SkipButton id={question.id} position={`${secondX} 0 0`} />
                </Actions>

                {question.hintText && (
                  <HelpPointButton
                    id={`helpOpenButton_${question.id}`}
                    position={getHelpPointButtonPosition(modalHeight)}
                  />
                )}
              </QuestionWrapper>
              {question.hintText && (
                <HelpWrapper
                  id={question.id}
                  height={getHelpWrapperHeight(
                    question.hintText,
                    modalHeight,
                    language
                  )}
                  text={question.hintText}
                />
              )}
            </Fragment>
          )
        })}
      </Fragment>
    )
  }, [questions, activityMode, language])

  return (
    <Fragment>
      {getComponents()}
      <CompleteButton position="0 -1.54 1.3" />
    </Fragment>
  )
}

export default memo(Preview)
