import { FC, Fragment, memo, useCallback, useEffect, useState } from 'react'
import { useSpeechRecognition } from 'react-speech-recognition'

import {
  useAnalyzeFreeSpeechAnswerMutation,
  useAnalyzeGuidedAnswerMutation,
  useFinishActivityMutation,
  useSaveQuestionResultMutation,
  useStartActivityMutation,
  useTranscribeAnswerMutation
} from '../../app/api/activityApi'
import {
  ActivityMode,
  IAnswer,
  IQuestion,
  VrMode,
  logVrInfo
} from '../../shared'
import HandControls from '../../shared/ui/hand-controls'
import StartButton from '../../shared/ui/start-button'
import Base from './components/base'
import { registerBasePanel } from './components/base/panel'
import Preview from './components/preview'
import { registerPreviewPanel } from './components/preview/panel'

declare global {
  interface Window {
    transcription: string
  }
}

interface Props {
  questions: IQuestion[]
  sceneUrl: string
  language: string
  mode: VrMode
  activityMode: ActivityMode
  assignedActivityId?: string | null
  completeCallback: () => void
}

const VrSceneWidget: FC<Props> = ({
  questions,
  sceneUrl,
  assignedActivityId,
  language,
  completeCallback,
  mode,
  activityMode
}) => {
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition()
  const [answersResults, setAnswersResults] = useState<IAnswer[]>([])

  const [transcribeRequest] = useTranscribeAnswerMutation()
  const [guidedAnalyze] = useAnalyzeGuidedAnswerMutation()
  const [freeSpeechAnalyze] = useAnalyzeFreeSpeechAnswerMutation()
  const [startTracking] = useStartActivityMutation()
  const [finishTracking] = useFinishActivityMutation()
  const [saveQuestionResult] = useSaveQuestionResultMutation()

  const startCallback = useCallback(async () => {
    if (assignedActivityId) {
      return await startTracking({ id: assignedActivityId }).unwrap()
    }
  }, [assignedActivityId, startTracking])

  const transcribe = useCallback(async (formData: FormData) => {
    await transcribeRequest({ formData })
      .unwrap()
      .then((res) => {
        window.transcription = res.transcription
      })
  }, [])

  useEffect(() => {
    const { userAgent } = navigator
    const isNotSupportedBrowser =
      userAgent.includes('Android') || userAgent.includes('Linux')

    const isSupport = browserSupportsSpeechRecognition && !isNotSupportedBrowser

    if (mode === VrMode.preview) {
      registerPreviewPanel({
        mode: VrMode.preview,
        questions,
        completeCallback
      })
    } else {
      registerBasePanel({
        questions,
        resetTranscript,
        setAnswersResults,
        browserSupportsSpeechRecognition: isSupport,
        language,
        mode,
        guidedAnalyze,
        freeSpeechAnalyze,
        transcribe,
        finishTracking,
        saveQuestionResult,
        completeCallback,
        startCallback,
        activityMode
      })
    }

    logVrInfo(
      mode,
      activityMode,
      browserSupportsSpeechRecognition,
      !isNotSupportedBrowser,
      isSupport
    )

    return () => {
      delete AFRAME.components['info-panel']
    }
  }, [])

  useEffect(() => {
    window.transcription = transcript
  }, [transcript])

  const getComponents = () => {
    if (mode === VrMode.preview)
      return (
        <Preview
          questions={questions}
          language={language}
          activityMode={activityMode}
        />
      )

    return (
      <Base
        questions={questions}
        language={language}
        activityMode={activityMode}
        answersResults={answersResults}
      />
    )
  }

  const gazeComponents = () => {
    return (
      <Fragment>
        <a-entity position="0 3 -2.5" id="ui">
          <StartButton />
          {getComponents()}
        </a-entity>
        <a-camera
          user-height="0"
          wasd-controls-enabled="false"
          look-controls-enabled="true"
        >
          <a-cursor raycaster="objects: .raycastable" />
        </a-camera>
      </Fragment>
    )
  }

  const handComponents = () => {
    return (
      <Fragment>
        <HandControls />
        <a-camera user-height="0" wasd-controls-enabled="false">
          <a-entity id="ui">
            <StartButton />
            {getComponents()}
          </a-entity>
        </a-camera>
      </Fragment>
    )
  }

  return (
    <a-scene
      cursor="rayOrigin: mouse; fuse: false"
      raycaster="objects: .raycastable"
      xr-mode-ui="XRMode: vr;"
    >
      <a-light type="ambient" color="white"></a-light>
      <a-assets timeout="3500">
        <video id="video" crossOrigin="anonymous" preload={'auto'}>
          <source type="video/mp4" src={sceneUrl} />
        </video>
      </a-assets>

      <a-entity info-panel />

      {mode === VrMode.demo ? gazeComponents() : handComponents()}

      <a-videosphere rotation="0 285 0" src="#video" />
    </a-scene>
  )
}

export default memo(VrSceneWidget)
