import 'aframe'

import {
  registerCompleteBtn,
  registerSkipBtn,
  registerStartBtn,
  VrComponentOptions,
  VrMode
} from '../../../../shared'

export function registerPreviewPanel(params) {
  const { mode, questions, completeCallback } = params

  delete AFRAME.components['info-panel']

  registerSkipBtn()

  registerCompleteBtn(completeCallback)

  registerStartBtn()

  AFRAME.registerComponent('info-panel', {
    ...VrComponentOptions,
    init: function () {
      this.ui = this.getElementById('ui')
      this.videoEl = this.getElementById('video')

      questions.forEach((question, index) => {
        this.videoEl = this.getElementById('video')
        this.videoEl.pause()
        this[`isAnsweredQuestion_${question.id}`] = false
        const isHelpExist = this.getElementById(`help_${question.id}`)

        this.videoEl.addEventListener('timeupdate', () => {
          if (
            this.videoEl.currentTime >= question.breakpoint &&
            !this[`isAnsweredQuestion_${question.id}`]
          ) {
            this.videoEl.pause()
            this.toggleElementVisibility(`questionWrapper_${question.id}`, true)
            this[`isAnsweredQuestion_${question.id}`] = true

            this.actionsVisibilityToggle(question.id, true)

            if (isHelpExist) {
              this.helpOpenBtnStateToggle(question.id, true)
            }
          }
        })

        if (this.getElementById(`repeatButton_${question.id}`)) {
          this.getElementById(`repeatButton_${question.id}`).addEventListener(
            'click',
            () => {
              this[`isAnsweredQuestion_${question.id}`] = false
              this.repeatBtnClick(question.id, questions, index)
            }
          )
        }

        if (isHelpExist) {
          this.getElementById(`helpOpenButton_${question.id}`).addEventListener(
            'click',
            () => this.toggleHelp(question.id, false)
          )
          this.getElementById(
            `helpCloseButton_${question.id}`
          ).addEventListener('click', () => this.toggleHelp(question.id, true))
        }
      })

      this.hideElements = this.hideElements.bind(this)

      this.videoEl.addEventListener('ended', () => {
        this.completeBtnStateToggle(true)
        if (mode !== VrMode.preview) {
          this.toggleElementVisibility('resultsWrapper', true)
        }
      })
      this.ui.object3D.position.x = 0
      this.ui.object3D.position.y = 1.6
      this.ui.object3D.position.z = -2.5
      if (AFRAME.utils.device.isMobileVR()) {
        this.ui.object3D.position.z = -3.5
        this.ui.object3D.position.y = 1.25
      }
    }
  })
}
