import api from '.'
import { API_ROUTES, IActivity } from '../../shared'

export const activityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActivity: builder.query<
      IActivity,
      { activityId: string; isDemo: boolean }
    >({
      query: ({ activityId, isDemo }) => ({
        url: isDemo
          ? API_ROUTES.DEMO_ACTIVITY(activityId)
          : API_ROUTES.ACTIVITY(activityId),
        method: 'GET'
      }),
      providesTags: ['Activity']
    }),
    transcribeAnswer: builder.mutation<
      { transcription: string },
      { formData: FormData }
    >({
      query: ({ formData }) => ({
        url: API_ROUTES.TRANSCRIBE,
        method: 'POST',
        data: formData
      })
    }),
    analyzeGuidedAnswer: builder.mutation<
      void,
      { formData: FormData; id: string }
    >({
      query: ({ formData, id }) => ({
        url: API_ROUTES.GUIDED_ANALYZE(id),
        method: 'POST',
        data: formData
      })
    }),
    analyzeFreeSpeechAnswer: builder.mutation<
      void,
      {
        formData: FormData
        questionId: string
      }
    >({
      query: ({ formData, questionId }) => {
        return {
          url: API_ROUTES.FREE_SPEECH_ANALYZE(questionId),
          method: 'POST',
          data: formData
        }
      }
    }),
    startActivity: builder.mutation<{ id: string }, { id: string }>({
      query: ({ id }) => ({
        url: API_ROUTES.STUDENT_RESULTS(id),
        method: 'POST'
      })
    }),
    finishActivity: builder.mutation<
      { id: string },
      { id: string; finishedAt: string }
    >({
      query: ({ id, finishedAt }) => ({
        url: API_ROUTES.STUDENT_RESULTS(id),
        method: 'PATCH',
        data: { finishedAt }
      })
    }),
    saveQuestionResult: builder.mutation<
      { id: string },
      { id: string; score: number }
    >({
      query: ({ id, score }) => ({
        url: API_ROUTES.STUDENT_RESULTS(id),
        method: 'PATCH',
        data: { score }
      })
    })
  })
})

export const {
  useGetActivityQuery,
  useLazyGetActivityQuery,
  useTranscribeAnswerMutation,
  useAnalyzeGuidedAnswerMutation,
  useAnalyzeFreeSpeechAnswerMutation,
  useStartActivityMutation,
  useFinishActivityMutation,
  useSaveQuestionResultMutation
} = activityApi
