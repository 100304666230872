import { FC, Fragment, memo, useCallback, useMemo } from 'react'

import {
  ActivityMode,
  getActionsPosition,
  getActionsXPositions,
  getCheckIconPosition,
  getHelpPointButtonPosition,
  getHelpWrapperHeight,
  getModalTitlePosition,
  getQuestionModalHeight,
  getNotificationIconPosition,
  getNotificationTextPosition,
  getTitlePosition,
  IAnswer,
  IQuestion
} from '../../../../shared'
import Actions from '../../../../shared/ui/actions'
import AnswerItem from '../../../../shared/ui/answer-item'
import HelpPointButton from '../../../../shared/ui/help-point-button'
import HelpWrapper from '../../../../shared/ui/help-wrapper'
import ProcessingNotification from '../../../../shared/ui/processing-notification'
import QuestionTitle from '../../../../shared/ui/question-title'
import QuestionWrapper from '../../../../shared/ui/question-wrapper'
import RecordButton from '../../../../shared/ui/record-button'
import RepeatButton from '../../../../shared/ui/repeat-button'
import ResultsWrapper from '../../../../shared/ui/results-wrapper'
import RetryNotification from '../../../../shared/ui/retry-notification'
import SkipButton from '../../../../shared/ui/skip-button'

interface Props {
  questions: IQuestion[]
  activityMode: ActivityMode
  language: string
  answersResults: IAnswer[]
}

const Base: FC<Props> = ({
  activityMode,
  answersResults,
  language,
  questions
}) => {
  const getActions = useCallback(
    (questionId: string, modalHeight: number) => {
      const actionsPostion = getActionsPosition(modalHeight)

      if (activityMode === ActivityMode.free_speech) {
        const [firstX, secondX, thirdX] = getActionsXPositions(3)

        return (
          <Actions id={questionId} position={actionsPostion}>
            <RepeatButton id={questionId} position={`${firstX} 0 0`} />
            <RecordButton id={questionId} position={`${secondX} 0 0`} />
            <SkipButton id={questionId} position={`${thirdX} 0 0`} />
          </Actions>
        )
      }

      const [firstX, secondX] = getActionsXPositions(2)
      return (
        <Actions id={questionId} position={actionsPostion}>
          <RepeatButton id={questionId} position={`${firstX} 0 0`} />
          <RecordButton id={questionId} position={`${secondX} 0 0`} />
        </Actions>
      )
    },
    [activityMode]
  )

  const getComponents = useCallback(() => {
    return (
      <Fragment>
        {questions.map((question) => {
          const answersCount =
            activityMode !== ActivityMode.free_speech
              ? question.answers.length
              : 1
          const modalHeight = getQuestionModalHeight(
            answersCount,
            question.text.length
          )
          const iconPosition = getNotificationIconPosition(modalHeight)
          const textPosition = getNotificationTextPosition(modalHeight)

          return (
            <Fragment key={question.id}>
              <QuestionWrapper
                id={`questionWrapper_${question.id}`}
                height={modalHeight}
              >
                <QuestionTitle
                  id={`question_${question.id}`}
                  value={question.text}
                  position={getModalTitlePosition(
                    modalHeight,
                    question.text.length
                  )}
                />
                {activityMode !== ActivityMode.free_speech &&
                  question.answers.map((answer, key) => {
                    return (
                      <AnswerItem
                        key={`key_${answer.id}`}
                        answerId={answer.id}
                        isCorrect={answer.isCorrect}
                        titlePosition={getTitlePosition(
                          key,
                          modalHeight,
                          question.text.length
                        )}
                        value={answer.text}
                        checkIconPosition={getCheckIconPosition(
                          key,
                          modalHeight,
                          question.text.length
                        )}
                      />
                    )
                  })}
                <RetryNotification
                  questionId={question.id}
                  iconPosition={iconPosition}
                  textPosition={textPosition}
                />
                <ProcessingNotification
                  questionId={question.id}
                  iconPosition={iconPosition}
                  textPosition={textPosition}
                />
                {getActions(question.id, modalHeight)}
                {question.hintText && (
                  <HelpPointButton
                    id={`helpOpenButton_${question.id}`}
                    position={getHelpPointButtonPosition(modalHeight)}
                  />
                )}
              </QuestionWrapper>
              {question.hintText && (
                <HelpWrapper
                  id={question.id}
                  height={getHelpWrapperHeight(
                    question.hintText,
                    modalHeight,
                    language
                  )}
                  text={question.hintText}
                />
              )}
            </Fragment>
          )
        })}
      </Fragment>
    )
  }, [activityMode, questions, language])

  return (
    <Fragment>
      {getComponents()}
      <ResultsWrapper
        answersResults={answersResults}
        activityMode={activityMode}
        allQuestionsCount={questions.length}
      />
    </Fragment>
  )
}

export default memo(Base)
