import { FC, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useLazyGetActivityQuery } from '../../app/api/activityApi'
import { VrMode } from '../../shared'
import Loader from '../../shared/ui/loader'
import VrSceneWidget from '../../widgets/vr-scene-widget'
import { Root } from './styles'

const DemoPage: FC = () => {
  const params = useParams<{
    activityId: string
  }>()
  const mode = VrMode.demo

  const [getActivity, { isLoading, data }] = useLazyGetActivityQuery()

  const completeCallback = useCallback(() => {
    location.reload()
  }, [])

  useEffect(() => {
    sessionStorage.clear()
    getActivity({ isDemo: true, activityId: params.activityId as string })
  }, [getActivity, params.activityId])

  if (isLoading || !data) return <Loader />

  return (
    <Root>
      <VrSceneWidget
        mode={mode}
        activityMode={data.mode}
        questions={data.questions}
        sceneUrl={data.sceneUrl}
        language={data.unit.course.language || 'en'}
        completeCallback={completeCallback}
      />
    </Root>
  )
}

export default DemoPage
