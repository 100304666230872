import 'aframe'

import { VrComponentOptions } from '.'

export const registerSkipBtn = () => {
  delete AFRAME.components['skip-button']

  AFRAME.registerComponent('skip-button', {
    schema: {
      questionId: {
        type: 'string'
      }
    },
    ...VrComponentOptions,
    parent: undefined,
    init: function () {
      this.parent =
        document.querySelector('[info-panel]').components['info-panel']
      this.el.addEventListener('click', this.events.click)
    },
    events: {
      click: function () {
        if (this.parent) {
          // eslint-disable-next-line
          // @ts-ignore
          this.parent.videoEl.play()
          // eslint-disable-next-line
          // @ts-ignore
          this.parent.hideElements(this.data.questionId)
        }
      }
    },
    remove: function () {
      this.el.removeEventListener('click', this.events.click)
    }
  })
}

export const registerCompleteBtn = (cb?: () => Promise<void>) => {
  delete AFRAME.components['complete-button']

  AFRAME.registerComponent('complete-button', {
    ...VrComponentOptions,
    init: function () {
      this.el.addEventListener('click', this.events.click)
    },
    events: {
      click: async function () {
        if (cb) {
          await cb()
        }
      }
    },
    remove: function () {
      this.el.removeEventListener('click', this.events.click)
    }
  })
}

export const registerStartBtn = (cb?: () => Promise<void>) => {
  delete AFRAME.components['start-button']

  AFRAME.registerComponent('start-button', {
    ...VrComponentOptions,
    parent: undefined,
    started: false,
    init: function () {
      this.parent =
        document.querySelector('[info-panel]').components['info-panel']

      window.addEventListener('online', this.events.online)

      window.addEventListener('offline', this.events.offline)

      this.el.addEventListener('click', this.events.click)
    },
    events: {
      click: async function () {
        if (cb) {
          await cb()
        }
        // eslint-disable-next-line
        // @ts-ignore
        this.parent.videoEl.play()
        this.started = true
        this.toggleElementVisibility('startButton', false)
        this.toggleElementClickEvents('startButton', false)
      },
      offline: function () {
        if (!this.started) {
          this.el.removeEventListener('click', this.events.click)
          this.toggleElementVisibility('startButton', false)
          this.toggleElementVisibility('waitElement', true)
        }
      },
      online: function () {
        if (!this.started) {
          this.el.addEventListener('click', this.events.click)
          this.toggleElementVisibility('startButton', true)
          this.toggleElementVisibility('waitElement', false)
        }
      }
    },
    remove: function () {
      window.removeEventListener('online', this.events.online)

      window.removeEventListener('offline', this.events.offline)

      this.el.removeEventListener('click', this.events.click)
    }
  })
}
