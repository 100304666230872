import 'aframe'
import 'aframe-troika-text'
import React from 'react'

import { Theme } from '../../constants'
import { useCustomFont } from '../../hooks'

const StartButton = () => {
  const fontProps = useCustomFont()

  return (
    <a-entity position="-0.5 -2 0.5" visible="true">
      <a-entity
        visible="false"
        id="waitElement"
        geometry="primitive: plane;width: 0.5; height: 0.15"
        material={`src: url(/assets/button-material.svg); transparent: true;`}
        position="0.5 0.45 1"
      >
        <a-entity
          geometry="primitive: plane;width: 0.4; height: 0.15"
          material={`color: ${Theme.colors.blue}`}
          visible="true"
          position="0 0 0.001"
        >
          <a-troika-text
            anchor="center"
            align="center"
            value="Please wait"
            position="0 0 0"
            color={Theme.colors.white}
            font-size="0.07"
            line-height="1.4"
            {...fontProps}
          />
        </a-entity>
      </a-entity>
      <a-entity
        id="startButton"
        start-button
        geometry="primitive: plane;width: 0.5; height: 0.15"
        material={`color: ${Theme.colors.blue}`}
        position="0.5 0.45 1"
        class="raycastable menu-button"
        animation__scale="property: scale; to: 1.1 1.1 1.1; dur: 200; startEvents: mouseenter"
        animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
      >
        <a-entity
          geometry="primitive: plane;width: 0.4; height: 0.15"
          material={`color: ${Theme.colors.blue}`}
          visible="true"
          position="0 0 0.001"
        >
          <a-troika-text
            anchor="center"
            align="center"
            value="Click to start"
            position="0 0 0"
            color={Theme.colors.white}
            font-size="0.07"
            line-height="1.4"
            {...fontProps}
          />
        </a-entity>
      </a-entity>
    </a-entity>
  )
}

export default StartButton
