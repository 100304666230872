export enum QuestionType {
  test = 'test',
  voice = 'voice'
}

export interface IAnswer {
  id: string
  text: string
  isCorrect: boolean
}

export interface IQuestion {
  id: string
  text: string
  type: QuestionType
  breakpoint: number
  answers: IAnswer[]
  hintText?: string | null
}

export interface IPreviewQuestion {
  text: string
  answers: IAnswer[]
  language: string
  activityMode: ActivityMode
  hintText?: string | null
}

export enum ActivityMode {
  free_speech = 'free_speech',
  guided = 'guided'
}

export interface IActivity {
  id: string
  title: string
  description: string
  sceneUrl: string
  unit: {
    id: string
    course: {
      id: string
      language: string
    }
  }
  questions: IQuestion[]
  isDemo: boolean
  mode: ActivityMode
}
